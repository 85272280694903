<template>
  <div>
    <div>
      <div class="headline">Media</div>
      <span class="subtitle-1 light-grey">All your connected media</span>
    </div>
    <br />

    <v-container fluid grid-list-lg class="p-0">
      <v-layout wrap>
        <v-flex
          v-for="influencerChannel in filteredInfluencerChannels"
          :key="influencerChannel.uuid"
          lg4
          md6
          xs12
          class="flex-card"
          height="100%"
        >
          <influencer-channel-card
            :influencer-channel="influencerChannel"
            class="grow"
            @input="influencerChannel = $event"
          ></influencer-channel-card>
        </v-flex>

        <v-flex lg4 md6 xs12 class="flex-card" height="100%">
          <v-card class="grow p-3">
            <v-container fluid fill-height>
              <v-layout align-center justify-center>
                <router-link
                  :to="{ name: 'channel.create' }"
                  tag="div"
                  class="cursor-pointer"
                >
                  <v-icon :size="80" color="#9c27b0">fas fa-plus-circle</v-icon>
                </router-link>
              </v-layout>
            </v-container>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import InfluencerChannelCard from "../../cards/InfluencerChannelCard";
export default {
  components: {
    InfluencerChannelCard
  },
  data: () => ({
    influencerChannels: []
  }),
  methods: {
    ...mapActions("loading", ["setPageLoading"]),
    ...mapActions("influencer", ["getInfluencerChannels"])
  },
  computed: {
    ...mapState("core/auth", ["user"]),

    filteredInfluencerChannels() {
      return this.influencerChannels.filter(item => item.active);
    }
  },
  created() {
    // Display loading so list isn't empty
    this.setPageLoading(true);

    this.getInfluencerChannels({
      influencer_uuid: this.user.influencer.uuid
    }).then(data => {
      this.influencerChannels = data;
      this.setPageLoading(false);
    });
  }
};
</script>
